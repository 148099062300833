import idair from "../assets/funding/idair.png";
import cmc from "../assets/funding/cmc.png";
// import wellcome from "../assets/funding/wellcome_w.png";
import wellcome from "../assets/funding/wellcome_r.png";
import varian from "../assets/funding/varian.jpg";
import icmr from "../assets/funding/icmr.png";

export const fundingData = [
    {
        "title": "Early Career Fellowship 2020-24",
        "image": wellcome,
    },
    {
        "title": "Pathfinder project grant",
        "image": idair,
    },
    {
        "title": "Fluid Research Major Grant 2021-22",
        "image": cmc,
    },
    {
        "title": "",
        "image": varian,
    },
    {
        "title": "",
        "image": icmr,
    },
]